import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { heading } from 'styles/typography'
import theme from 'styles/theme'

export const Wrapper = styled.article``

export const ThumbnailWrapper = styled(Link)`
  position: relative;
  margin-bottom: 2.4rem;
  display: block;

  &:before {
    content: '';
    display: block;
    padding-top: 67.3%;
  }
`

export const Thumbnail = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${theme.color.background.gray};
`

export const Category = styled.div`
  ${heading.xxs};
  margin-bottom: 1.2rem;
`

export const Title = styled(Link)`
  ${heading.m};
  margin-bottom: 1.2rem;
`

export const MoreLink = styled(Link)`
  text-decoration: underline;
`
