import React from 'react'
import { TertiaryLink } from 'components/links'
import BlogPost from 'components/blog-post'
import { blogCategoryUrl } from 'common/urls'
import { Wrapper, Inner, TitleWrapper, Title, Grid } from './styled'

type Props = {
  isEven: boolean
  category: BlogCategory
  posts: readonly BlogPost[]
}

const CategorySection = ({ category, isEven, posts }: Props) => {
  return (
    <Wrapper hasBackground={!isEven}>
      <Inner>
        <TitleWrapper>
          <Title>{category.title}</Title>
          <TertiaryLink
            to={blogCategoryUrl(category.slug)}
            aria-label={`View posts in ${category.title} category`}
          >
            View all
          </TertiaryLink>
        </TitleWrapper>
        <Grid>
          {posts.map(post => (
            <BlogPost key={post.fields.slug} post={post} />
          ))}
        </Grid>
      </Inner>
    </Wrapper>
  )
}

export default CategorySection
