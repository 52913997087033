import styled from 'styled-components'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { heading } from 'styles/typography'

export const Wrapper = styled.section<{ hasBackground: boolean }>`
  background: ${p =>
    p.hasBackground ? theme.color.background.light : 'transparent'};
  padding: 6.4rem 3rem;

  ${mq.large} {
    padding: 12.4rem 0 14rem;
  }
`

export const Inner = styled.div`
  max-width: 108rem;
  margin: 0 auto;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;

  ${mq.medium} {
    margin-bottom: 6.7rem;
  }
`

export const Title = styled.h4`
  ${heading.l};
  margin-bottom: 0;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  ${mq.medium} {
    grid-template-columns: 1fr 1fr;
  }
`
