import React from 'react'
import { Link } from 'gatsby'
import { blogAuthorUrl } from 'common/urls'
import { Wrapper, Thumbnail, Details, Category, Title, Author } from './styled'

type Props = {
  post: BlogPost
}

const ArticleItem = ({ post }: Props) => {
  return (
    <Wrapper>
      <Link to={post.fields.slug} aria-label={post.frontmatter.title}>
        {/* @ts-expect-error */}
        <Thumbnail fluid={post.frontmatter.thumbnailSmall.childImageSharp.fluid}/>
      </Link>
      <Details>
        <Category>{post.frontmatter.category.title}</Category>
        <Title to={post.fields.slug}>{post.frontmatter.title}</Title>
        <Author to={blogAuthorUrl(post.frontmatter.author.slug)}>
          By {post.frontmatter.author.fullName}
        </Author>
      </Details>
    </Wrapper>
  )
}

export default ArticleItem
