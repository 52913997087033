import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { heading } from 'styles/typography'

export const Wrapper = styled.li`
  margin-bottom: 4rem;
  display: flex;
`

export const Thumbnail = styled(Img)`
  width: 7.2rem;
  height: 4.7rem;
  margin-right: 2rem;
`

export const Details = styled.div``

export const Category = styled.div`
  ${heading.xxs};
`

export const Title = styled(Link)`
  ${heading.xs};
  display: inline-block;
  margin-bottom: 1.6rem;
`

export const Author = styled(Link)`
  ${heading.xs};
  font-size: 1.4rem;
  display: block;
`
