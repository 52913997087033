import React from 'react'

import { ScreenSize } from 'styles/mq'
import FeaturedSlider from 'components/featured-slider'
import {
  Wrapper,
  BlockLink,
  Article,
  Thumbnail,
  Details,
  Category,
  Title,
  Excerpt,
} from './styled'

type Props = {
  posts: readonly BlogPost[]
}

const Featured = ({ posts }: Props) => {
  return (
    <Wrapper>
      <FeaturedSlider>
        {posts.map(post => (
          <BlockLink key={post.id} to={post.fields.slug}>
            <Article>
              <Thumbnail
                // @ts-expect-error
                fluid={{
                  ...post.frontmatter.thumbnail.childImageSharp.fluid,
                  sizes: `(max-width: ${ScreenSize.SM}px) 100vw, 66vw`,
                }}
              />
              <Details>
                <Category>{post.frontmatter.category.title}</Category>
                <Title>{post.frontmatter.title}</Title>
                <Excerpt>{post.excerpt}</Excerpt>
              </Details>
            </Article>
          </BlockLink>
        ))}
      </FeaturedSlider>
    </Wrapper>
  )
}

export default Featured
