import React from 'react'
import {
  Wrapper,
  ThumbnailWrapper,
  Thumbnail,
  Title,
  Category,
  MoreLink,
} from './styled'

type Props = {
  post: BlogPost
}

const BlogPost = ({ post }: Props) => {
  return (
    <Wrapper>
      <ThumbnailWrapper
        to={post.fields.slug}
        aria-label={post.frontmatter.title}
      >
        {/* @ts-expect-error */}
        <Thumbnail fluid={post.frontmatter.thumbnail.childImageSharp.fluid} />
      </ThumbnailWrapper>
      <Category>{post.frontmatter.category.title}</Category>
      <Title to={post.fields.slug}>{post.frontmatter.title}</Title>
      <p>{post.excerpt}</p>
      <MoreLink to={post.fields.slug}>Read full article</MoreLink>
    </Wrapper>
  )
}
export default BlogPost
