import styled from 'styled-components'
import theme from 'styles/theme'
import button from 'components/button'
import mq from 'styles/mq'

export const Controls = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`

export const ControlButton = styled(button as any)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${theme.color.accent.primary};
  border: none;
  width: 4.3rem !important;
  height: 4.3rem;
  padding: 0;

  ${mq.medium} {
    width: 6.8rem !important;
    height: 6.8rem;
  }

  &:first-child {
    svg {
      transform: rotateZ(180deg);
    }
  }
`
