import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { heading } from 'styles/typography'

export const Wrapper = styled.section`
  position: relative;

  ${mq.medium} {
    width: 66%;
  }
`

export const BlockLink = styled(Link)`
  /* Because react-slick overrides display prop and breaks layout */
  display: block !important;
`

export const Article = styled.div`
  position: relative;
  height: 58rem;

  ${mq.large} {
    height: 80rem;
  }
`

export const Thumbnail = styled(Img)`
  display: block;
  height: 100%;
`

export const Details = styled.div`
  background: ${theme.color.background.light};
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 4rem 9.6rem 4rem 3.2rem;
  width: 33rem;

  ${mq.large} {
    width: 65rem;
    padding: 4.2rem 5.2rem;
  }
`

export const Category = styled.div`
  ${heading.xxs};
  margin-bottom: 1.2rem;
`

export const Title = styled.h4`
  ${heading.l};
  margin-bottom: 1.6rem;
`

export const Excerpt = styled.p`
  margin: 0;
`
