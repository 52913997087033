import styled from 'styled-components'
import { heading } from 'styles/typography'
import theme from 'styles/theme'
import mq from 'styles/mq'

export const Hero = styled.section`
  background: ${theme.color.background.light};
`

export const Inner = styled.div`
  max-width: 180rem;
  margin: 0 auto;

  ${mq.medium} {
    display: flex;
  }

  ${mq.large} {
    height: 80rem;
  }
`

export const TopArticles = styled.div`
  padding: 6rem 3rem 7.2rem;

  ${mq.large} {
    padding: 5.6rem 4.5rem;
  }
`

export const TopTitle = styled.h5`
  ${heading.m};
`

export const ArticleList = styled.ul``
