import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from 'components/layout'
import Categories from 'components/categories'
import SEO from 'components/seo'
import Featured from './featured'
import CategorySection from './category-section'
import ArticleItem from './article-item'
import { Hero, Inner, TopArticles, TopTitle, ArticleList } from './styled'

const query = graphql`
  query MyQuery {
    featuredArticles: allMarkdownRemark(
      filter: { frontmatter: { featureIndex: { gte: 0 } } }
      sort: { fields: frontmatter___featureIndex, order: DESC }
    ) {
      nodes {
        ...postFields
      }
    }
    topArticles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: null } } }
      limit: 5
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        ...postFields
      }
    }
    contributors: allAuthorsJson {
      nodes {
        ...authorFields
      }
    }
    allCategoriesJson {
      nodes {
        id
        title
        slug
        topPosts(limit: 2) {
          ...postFields
        }
      }
    }
  }
`

type BlogCategoryWithPosts = BlogCategory & {
  topPosts: readonly BlogPost[]
}

type QueryResult = {
  featuredArticles: {
    nodes: readonly BlogPost[]
  }
  topArticles: {
    nodes: readonly BlogPost[]
  }
  allCategoriesJson: {
    nodes: readonly BlogCategoryWithPosts[]
  }
  contributors: {
    nodes: readonly Author[]
  }
}

const Blog = () => {
  const data = useStaticQuery<QueryResult>(query)
  const categories = data.allCategoriesJson.nodes
  const topArticles = data.topArticles.nodes
  const featuredArticles = data.featuredArticles.nodes

  return (
    <Layout withHeaderBorder>
      <SEO title="The Swim" />
      <Categories categories={categories} />
      <Hero>
        <Inner>
          <Featured posts={featuredArticles} />
          <TopArticles>
            <TopTitle>Most Recent</TopTitle>
            <ArticleList>
              {topArticles.map(article => (
                <ArticleItem key={article.frontmatter.title} post={article} />
              ))}
            </ArticleList>
          </TopArticles>
        </Inner>
      </Hero>
      {categories.map((category, index) => (
        <CategorySection
          key={category.id}
          category={category}
          posts={category.topPosts}
          isEven={index % 2 === 0}
        />
      ))}
    </Layout>
  )
}

export default Blog
