import React, { useRef, FC } from 'react'
import Slider from 'react-slick'
import RightArrow from 'components/icons/right-arrow'
import { Controls, ControlButton } from './styled'

const FeaturedSlider: FC = ({ children }) => {
  const sliderRef = useRef<Slider>(null)

  return (
    <>
      <Slider
        infinite
        speed={500}
        autoplay={true}
        autoplaySpeed={5000}
        ref={sliderRef}
        arrows={false}
      >
        {children}
      </Slider>

      {Array.isArray(children) && children.length > 1 && (
        <Controls>
          <ControlButton
            onClick={() => sliderRef.current?.slickPrev()}
            aria-label="Previous slide"
          >
            <RightArrow />
          </ControlButton>
          <ControlButton
            onClick={() => sliderRef.current?.slickNext()}
            aria-label="Next slide"
          >
            <RightArrow />
          </ControlButton>
        </Controls>
      )}
    </>
  )
}

export default FeaturedSlider
